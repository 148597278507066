import React from "react";
import {observer} from "mobx-react-lite";
import {Form as FinalForm} from "react-final-form";
import {toJS} from "mobx";
import {Button, Form, Grid, Header, Input as UiInput, List, Radio} from "semantic-ui-react";
import AppWrapper from "../../components/AppWrapper";
import {Select} from "../../components/FormElements";
import {map} from "lodash";
import RootStoreContext from "../../stores/RootStore";

const Tab = observer(() => {
  const {ah, uiState} = React.useContext(RootStoreContext);
  const handleChange = e => {
    const {target} = e;
    ah.updateValue(target.name, target.value);
  };
  const Switch = ({name, options, handleChange}) => (
    <Form.Field style={{display: "flex", justifyContent: "start", gap: "1em"}}>
      {map(options, entry => (
        <Radio
          key={entry.value}
          label={entry.label}
          name={name}
          value={entry.value}
          checked={ah[name] === entry.value}
          onChange={handleChange}
        />
      ))}
    </Form.Field>
  );

  return (
    <AppWrapper>
      <FinalForm
        onSubmit={(values, from, callback) => {
        }}
        initialValues={toJS(ah)}
        render={({handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4">Private Cloud</Header>
            <List>
              {map(ah.privateCloud, item => {
                if (parseInt(item.qty) === 0) {
                  return "";
                }
                return (
                  <List.Item key={item.id} id={item.id}>
                    <div className={"inline field"}
                         style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <label>
                        {[
                          item.cpu.target.label,
                          item.ram.target.label,
                          item.disk.target.label,
                          ah.calculatePrivateCloud(item.id),
                        ].filter(item => item !== null).join(" - ")}
                      </label>
                      <UiInput
                        name={`privateCloud[${item.id}]`}
                        type={"number"}
                        onChange={e => {
                          ah.updatePrivateCloud(item.id, e.target.value);
                        }}
                        value={item.qty}
                        min={0}
                        max={9999}
                        action={{
                          icon: "close",
                          type: "button",
                          onClick: e => {
                            e.preventDefault();
                            ah.updatePrivateCloud(item.id, 0);
                          },
                        }}
                      />
                    </div>
                  </List.Item>
                );
              })}
            </List>
            <Grid>
              <Grid.Row>
                <Grid.Column width={uiState.isDesktop ? 7 : 16}>
                  <Select name={"cpu"} label={"CPU"} options={ah.cpuOptions} onChange={handleChange}/>
                </Grid.Column>
                <Grid.Column width={uiState.isDesktop ? 4 : 16}>
                  <Select name={"ram"} label="RAM" options={ah.ramOptions} onChange={handleChange}/>
                </Grid.Column>
                <Grid.Column width={uiState.isDesktop ? 5 : 16}>
                  <Select name={"disk"} label="Disk" options={ah.diskOptions} onChange={handleChange}/>
                  <Switch
                    name={"disk_type"}
                    options={[{value: "ssd", label: "SSD"}, {value: "nvme", label: "NVMe"}]}
                    handleChange={(e, {name, value}) => {
                      ah.updateValue("disk", 0);
                      ah.updateValue(name, value);
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
              </Grid.Row>

              <div style={{padding: "0 1em 0", textAlign: "right", width: "100%"}}>
                <Button type={"button"} onClick={e => {
                  e.preventDefault();
                  ah.addPrivateCloud();
                }}>Add</Button>
              </div>
            </Grid>

            <Grid columns={uiState.isDesktop ? 2 : 1}>
              <Grid.Row>
                <Grid.Column>
                  <Select
                    name={"cluster_ip_num"}
                    label="Dedicated IP range"
                    options={ah.dedicatedIpRangeOptions}
                    onChange={handleChange}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Select
                    name={ah.traf_type === "mbps" ? "cluster_traf95_max" : "cluster_traf_max"}
                    label="Cluster Traffic"
                    options={ah.clusterTrafficOptions}
                    onChange={handleChange}
                  />
                  <Switch
                    name={"traf_type"}
                    options={[{value: "mbps", label: "Mbps"}, {value: "tb", label: "TB"}]}
                    handleChange={(e, {name, value}) => {
                      ah.updateValue("cluster_traf95_max", 0);
                      ah.updateValue("cluster_traf_max", 0);
                      ah.updateValue(name, value);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>

            </Grid>

          </Form>
        )}
      />
    </AppWrapper>
  );
});

export default Tab;
