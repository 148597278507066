import {makeAutoObservable, observable} from "mobx";

export class UiState {
  rootStore;
  language = "en";
  pendingRequestCount = 0;
  windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  constructor(rootStore) {
    makeAutoObservable(this, {rootStore: false, windowDimensions: observable.struct});
    this.rootStore = rootStore;
    window.onresize = () => {
      this.windowDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    };
  }

  get appIsInSync() {
    return this.pendingRequestCount === 0;
  }

  get isDesktop() {
    return this.windowDimensions.width > 760;
  }

  get isMobile() {
    return !this.isDesktop;
  }

  startRequest() {
    this.pendingRequestCount++;
  }

  finishRequest() {
    if (!this.appIsInSync) {
      this.pendingRequestCount--;
    }
  }
}