import "./index.css";

import React from "react";
import {render} from "react-dom";

import App from "./App";

const id = "hpc-frontend";

export const init = config => {
  render(<App {...config} />, document.getElementById(id));
};

render(<App tabs={[]} apiRoot={""} tariffName={""}/>, document.getElementById(id));
