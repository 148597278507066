import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Header, Form, List, Button} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import {find} from "lodash";
import AppWrapper from "../../components/AppWrapper";
import {Input, Select} from "../../components/FormElements";
import RootStoreContext from "../../stores/RootStore";
import {asAttributeLabel} from "../../utils/formatter";

const Tab = observer(() => {
  const {ah} = React.useContext(RootStoreContext);
  const handleChange = e => {
    const {target} = e;
    ah.updateValue(target.name, target.value);
  };
  const handleConfigChange = (item, quantity) => {
    ah.updatePublicCloud(item, quantity);
  };
  const handleReset = name => {
    ah.updateValue(name, 0);
  };

  return (
    <AppWrapper>
      <FinalForm
        onSubmit={() => {
        }}
        initialValues={toJS(ah)}
        render={({handleSubmit}) => (
          <Form onSubmit={handleSubmit}>

            <Header as="h4">Cloud Servers</Header>
            <List>
              {ah.publicCloud.map(item => {
                if (parseInt(item.prepaid.quantity) === 0) {
                  return "";
                }
                return (
                  <List.Item key={item.id}>
                    <div className={"inline field"}
                         style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <label>{find(ah.publicCloudOptions, option => option.value === item.id).text}</label>
                      <div className="ui action input">
                        <input
                          name={`publicCloud_${item.id}`}
                          type="number"
                          value={item.prepaid.quantity}
                          min={0}
                          max={9999}
                          onChange={e => handleConfigChange(item, e.target.value)}
                        />
                        <a className="ui icon button" onClick={e => {
                          e.preventDefault();
                          handleConfigChange(item, 0);
                        }}>
                          <i className="close icon"/>
                        </a>
                      </div>
                    </div>
                  </List.Item>
                );
              })}
            </List>
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
              <Select name={"public_cloud_id"} options={ah.publicCloudOptions} onChange={handleChange}/>
              <Button type={"button"} content={"Add"} onClick={e => {
                e.preventDefault();
                const item = find(toJS(ah.tariff.prices), price => parseInt(price.id) === parseInt(toJS(ah).public_cloud_id));
                ah.addPublicCloud(item);
              }} style={{margin: "1em 0 1em .5em"}}/>
            </div>

            <Header as="h4">Traffic & IPs</Header>
            <Input
              name={"server_traf_max"}
              label={`Extra Traffic, ${asAttributeLabel("overuse,server_traf_max")}`}
              handleChange={handleChange}
              handleReset={handleReset}
            />
            <Input
              name={"ip_num"}
              label={`Public IPs, ${asAttributeLabel("overuse,ip_num", "IP")}`}
              handleChange={handleChange}
              handleReset={handleReset}
            />
            <Input
              name={"anycast_ip_num"}
              label={`Anycast IPs, ${asAttributeLabel("overuse,anycast_ip_num", "IP")}`}
              handleChange={handleChange}
              handleReset={handleReset}
            />
          </Form>
        )}
      />
    </AppWrapper>
  );
});

export default Tab;
