import React from "react";
import {Menu, Segment, Container, Grid, Loader, Dimmer} from "semantic-ui-react";
import {filter} from "lodash";
import {Link, useRouteMatch} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import RootStoreContext from "../stores/RootStore";
import Sidebar from "./Sidebar";
import ConfigContext from "../ConfigContext";

const AppWrapper = observer(props => {
    const {tabs} = React.useContext(ConfigContext);
    const {ah, uiState} = React.useContext(RootStoreContext);
    const {t} = useTranslation();
    const match = useRouteMatch();
    const current = match.path;
    const routes = filter([
      {label: t("Private cloud"), path: "/private-cloud"},
      {label: t("Public cloud"), path: "/public-cloud"},
      {label: t("Dedicated servers"), path: "/dedicated-servers"},
      {label: t("Storage"), path: "/storage"},
      {label: t("DNS"), path: "/dns"},
    ], item => tabs.includes(item.path));
    const content = (ah.tariff) ? <Segment>{props.children}</Segment> :
      <Dimmer active inverted><Loader inverted content={t("Loading...")}/></Dimmer>;

    return (
      <>
        <Container style={{marginTop: "1rem"}}>
          <Menu stackable={uiState.isMobile}>
            {routes.map(route => (
              <Menu.Item key={route.path} as={Link} name={route.label} to={route.path} active={current === route.path}/>
            ))}
          </Menu>
          <Grid divided={"vertically"}>
            <Grid.Row columns={2}>
              <Grid.Column width={uiState.isDesktop ? 10 : 16}>
                {content}
              </Grid.Column>
              <Grid.Column width={uiState.isDesktop ? 6 : 16}>
                <Sidebar/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </>
    );
  },
);

export default AppWrapper;