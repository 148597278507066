import React, {useContext} from "react";
import PropTypes from "prop-types";
import {MemoryRouter as Router, Route, Switch} from "react-router-dom";
import {Container, Header} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {has, forEach} from "lodash";
import "semantic-ui-css/semantic.min.css";
import "./App.scss";
import ConfigContext from "./ConfigContext";
import i18n from "./i18n";

import PublicCloud from "./tabs/PublicCloud";
import PrivateCloud from "./tabs/PrivateCloud";
import Storage from "./tabs/Storage";
import DedicatedServers from "./tabs/DedicatedServers";
import DNS from "./tabs/DNS";
import RootStoreContext from "./stores/RootStore";

const App = props => {
  const {t} = useTranslation();
  const config = useContext(ConfigContext);
  const rootStore = React.useContext(RootStoreContext);
  forEach(["tabs", "apiRoot", "tariffName", "seller"], configOption => {
    if (has(props, configOption) && props[configOption].length) {
      config[configOption] = props[configOption];
    }
  });
  rootStore.initCalculators(config.apiRoot, config.tariffName, config.seller);

  return (
    <ConfigContext.Provider value={config}>
      <Router initialEntries={config.tabs} initialIndex={0}>
        <Switch>
          <Route exact path="/public-cloud" render={props => <PublicCloud {...props}/>}/>
          <Route exact path="/private-cloud" render={props => <PrivateCloud {...props}/>}/>
          <Route exact path="/storage" render={props => <Storage {...props}/>}/>
          <Route exact path="/dedicated-servers" render={props => <DedicatedServers {...props}/>}/>
          <Route exact path="/dns" render={props => <DNS {...props}/>}/>
          <Route path="*">
            <Container textAlign={"center"} style={{paddingTop: "3em"}}>
              <Header as={"h2"}>{t("not_found")} <code>{config.tabs[0]}</code></Header>
            </Container>
          </Route>
        </Switch>
      </Router>
    </ConfigContext.Provider>
  );
};

App.propTypes = {
  apiRoot: PropTypes.string,
  tariffName: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.string),
};

export default App;
