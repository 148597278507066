import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
    },
    lng: "en",
    fallbackLng: "en",
    keySeparator: false,
    resources: {
      en: {
        translation: {
          tb: "TB",
          items: "",

          16: "/28 - 16 IPs",
          32: "/27 - 32 IPs",
          64: "/26 - 64 IPs",
          128: "/25 - 128 IPs",
          256: "/24 - 256 IPs",
          512: "/23 - 512 IPs",
          1024: "/22 - 1024 IPs",
          2048: "/21 - 2048 IPs",

          "250 mbps": "250 Mbps",
          "500 mbps": "500 Mbps",
          "1000 mbps": "1 Gbps",
          "3000 mbps": "3 Gbps",
          "5000 mbps": "5 Gbps",
          "10000 mbps": "10 Gbps",

          total: "{{total}}/month",

          not_found: "No match route for",
        },
      },
      ru: {
        translation: {},
      },
    },
  });

export default i18n;
