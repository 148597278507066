import React from "react";
import AppWrapper from "../../components/AppWrapper";
import {Form, Header} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {Input} from "../../components/FormElements";
import {toJS} from "mobx";
import {Form as FinalForm} from "react-final-form";
import RootStoreContext from "../../stores/RootStore";
import {asAttributeLabel} from "../../utils/formatter";

const Tab = observer(() => {
  const {ah} = React.useContext(RootStoreContext);
  const handleChange = e => {
    const {target} = e;
    ah.updateValue(target.name, target.value);
  };
  const handleReset = name => {
    ah.updateValue(name, 0);
  };

  return (
    <AppWrapper>
      <FinalForm
        onSubmit={(values, from, callback) => {
        }}
        initialValues={toJS(ah)}
        render={({handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4">Block Storage</Header>
            <Input name={"block_hdd_l2_du"}
                   label={`HDD Volumes Replication L2, ${asAttributeLabel("overuse,block_hdd_l2_du")}`}
                   handleChange={handleChange} handleReset={handleReset}/>
            <Input name={"block_hdd_l3_du"}
                   label={`HDD Volumes Replication L3, ${asAttributeLabel("overuse,block_hdd_l3_du")}`}
                   handleChange={handleChange} handleReset={handleReset}/>
            <Input name={"block_ssd_l2_du"}
                   label={`SSD Volumes Replication L2, ${asAttributeLabel("overuse,block_ssd_l2_du")}`}
                   handleChange={handleChange} handleReset={handleReset}/>
            <Input name={"block_ssd_l3_du"}
                   label={`SSD Volumes Replication L3, ${asAttributeLabel("overuse,block_ssd_l3_du")}`}
                   handleChange={handleChange} handleReset={handleReset}/>

            <Header as="h4">Object Storage</Header>
            <Input
              name={"object_data"}
              label={`Triple replication, 3 zones, ${asAttributeLabel("overuse,object_data")}`}
              handleChange={handleChange}
              handleReset={handleReset}
            />
            <Input
              name={"object_traf"}
              label={`Outgoing traffic, ${asAttributeLabel("overuse,object_traf")}`}
              handleChange={handleChange}
              handleReset={handleReset}
            />
          </Form>
        )}
      />
    </AppWrapper>
  );
});

export default Tab;
