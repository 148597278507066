import React from "react";
import PropTypes from "prop-types";
import AppWrapper from "../../components/AppWrapper";

const Tab = () => (
  <AppWrapper>
    ...
  </AppWrapper>
);

export default Tab;
