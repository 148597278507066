import React from "react";
import {Field} from "react-final-form";
import {Form, Radio, Input as UiInput} from "semantic-ui-react";
import {map} from "lodash";

export const Input = ({name, label, handleChange, handleReset}) => (
  <Field name={name} onChange={handleChange}>
    {props => (
      <Form.Field inline style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        {label ? (<label>{label}</label>) : ""}
        <UiInput type={"number"} action={{
          icon: "close",
          type: "button",
          onClick: e => {
            e.preventDefault();
            handleReset(name);
          },
        }} min={0} max={9999} {...props.input} onChange={props.onChange}/>
      </Form.Field>
    )}
  </Field>
);

export const Select = ({name, label = "", options = [], onChange}) => {
  return (<Field name={name}>
    {props => {
      return (<Form.Field style={{marginTop: "1em"}}>
        {label ? (<label>{label}</label>) : ""}
        <select {...props.input} onChange={onChange}>
          <option value={""}>--</option>
          {options.map(option => <option key={option.key} value={option.value}>{option.text}</option>)}
        </select>
      </Form.Field>);
    }}
  </Field>);
};

export const Switch = ({name, onChange, options = []}) => {
  return (
    <Field name={name} onChange={onChange}>
      {props => {
        return (
          <Form.Field style={{marginTop: "2.3em"}}>
            {map(options, entry => (
              <Radio
                label={entry.label}
                onChange={props.onChange}
                {...props.input}
              />
            ))}
          </Form.Field>
        );
      }}
    </Field>
  );
};