import React from "react";

export const tabs = [
  "/private-cloud",
  "/public-cloud",
  // "/dedicated-servers",
  "/storage",
  // "/dns",
];

export const apiRoot = "https://hiapi.advancedhosting.com";

export const seller = "reseller";

export const tariffName = "Calculator-AH";

const ConfigContext = React.createContext({
  apiRoot: apiRoot,
  tariffName: tariffName,
  seller: seller,
  tabs: tabs,
});

export default ConfigContext;
