import React from "react";
import {Segment, Grid, Header, List, Dimmer, Loader} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import RootStoreContext from "./../stores/RootStore";
import {asPrice} from "../utils/formatter";
import {RowWithPrepaid, SimpleRow} from "./RowElements";
import {find, filter, forEach, uniq, some} from "lodash";
import {toJS} from "mobx";
import {useTranslation} from "react-i18next";

const collectInfo = (type, qty, props) => {
  if (!Object.keys(props).length) {
    return [0, ""];
  }
  if (type === "cpu") {
    return [
      parseInt(props["cpu:threads"]) * (parseInt(props["motherboard:cpu_sockets"]) || 1) * parseInt(qty),
      "",
    ];
  }
  if (type === "ram") {
    return [
      parseInt(props["ram:size"]) * parseInt(qty),
      props["ram:size"].split(" ")[1],
    ];
  }
  if (type === "disk") {
    return [
      parseInt(props["ssd:size"]) * parseInt(qty),
      props["ssd:size"].split(" ")[1],
    ];
  }
  if (type === "traffic") {
    return [
      parseInt(props.quantity) * parseInt(qty),
      props.unit,
    ];
  }
};

const stringifyInfo = (qty, units) => {
  const {t} = useTranslation();
  if (!qty.length) {
    return "";
  }
  const sum = qty.reduce((a, n) => parseInt(a) + parseInt(n));
  if (!sum) {
    return "";
  }
  const unit = uniq(units).join(" ");

  return `${sum} ${t(unit)}`;
};

const Sidebar = observer(() => {
  const {t} = useTranslation();
  const {ah, uiState} = React.useContext(RootStoreContext);

  // useEffect(() => {
  //   if (!ah.isLoading) {
  //     amazon.calculate(ah.prepareQueryParams(amazon.plan));
  //     azure.calculate(ah.prepareQueryParams(azure.plan));
  //     google.calculate(ah.prepareQueryParams(google.plan));
  //     digitalOcean.calculate(ah.prepareQueryParams(digitalOcean.plan));
  //   }
  // }, [ah.total]);

  const getPrepaid = type => {
    const charges = toJS(ah.charges);
    if (!charges.length) {
      return "";
    }
    const charge = find(charges, charge => charge.type.name === type);
    if (!charge) {
      return "";
    }
    if (type === "overuse,cluster_ip_num") {
      return t(charge.usage.quantity);
    }
    if (type === "overuse,cluster_traf95_max") {
      return t(charge.usage.quantity + " " + charge.usage.unit);
    }

    return charge.usage.quantity + " " + t(charge.usage.unit);
  };

  const getAmount = type => {
    const charges = toJS(ah.charges);
    if (!charges.length) {
      return "";
    }
    const charge = find(charges, charge => charge.type.name === type);
    if (!charge) {
      return "";
    }

    return asPrice(parseInt(charge.sum.amount), charge.sum.currency);
  };

  const getServersTotal = targetType => {
    let charges = toJS(ah.charges);
    if (!ah.charges.length) {
      return "";
    }
    charges = filter(charges, charge => charge.type.name === "monthly,hardware" && charge.target.type === targetType);
    if (!charges.length) {
      return "";
    }
    let total = 0;
    forEach(charges, charge => {
      if (charge.target) {
        total += parseInt(charge.sum.amount);
      }
    });

    return asPrice(parseInt(total), ah.currency);
  };
  const privateCloudInfo = type => {
    const qty = [], units = [];
    forEach(toJS(ah.privateCloud), entry => {
      const model = find(toJS(ah.models), model => parseInt(model.id) === parseInt(entry[type].target.id));
      let info = collectInfo(type, entry.qty || 0, model.props);
      qty.push(info[0]);
      units.push(info[1]);
    });

    return stringifyInfo(qty, units);
  };
  const publicCloudInfo = type => {
    const qty = [], units = [];
    forEach(toJS(ah.publicCloud), entry => {
      const config = find(toJS(ah.configs), config => parseInt(config.id) === parseInt(entry.target.id));
      let info = collectInfo(type, entry.prepaid.quantity || 0, config.props);
      qty.push(info[0]);
      units.push(info[1]);
    });

    return stringifyInfo(qty, units);
  };
  const publicCloudTraffic = () => {
    const qty = [], units = [];
    forEach(toJS(ah.publicCloud), entry => {
      const price = find(toJS(ah.prices), price => parseInt(price.target.id) === parseInt(entry.target.id) && price.type.name === "overuse,server_traf_max");
      let info = collectInfo("traffic", entry.prepaid.quantity, price.prepaid);
      qty.push(info[0]);
      units.push(info[1]);
    });

    return stringifyInfo(qty, units);
  };
  const publicCloudAmount = () => some(toJS(ah.publicCloud), entry => entry.prepaid.quantity > 0) ? asPrice(0) : "";

  return (
    <>
      <Segment.Group>
        <Segment>
          <Header as="h4">All Services</Header>
        </Segment>
        <Segment>
          <SimpleRow label={"Cloud Servers"} amount={getServersTotal("config")}/>
          <List style={{marginLeft: ".7em", marginTop: ".7em"}}>
            <List.Item>
              <RowWithPrepaid label={"vCPU"} prepaid={publicCloudInfo("cpu")}/>
            </List.Item>
            <List.Item>
              <RowWithPrepaid label={"RAM"} prepaid={publicCloudInfo("ram")}/>
            </List.Item>
            <List.Item>
              <RowWithPrepaid label={"DISK"} prepaid={publicCloudInfo("disk")}/>
            </List.Item>
          </List>
        </Segment>
        <Segment>
          <Header as="h4">Traffic & IPs</Header>
          <RowWithPrepaid
            label={"Included Traffic"}
            prepaid={publicCloudTraffic()}
            amount={publicCloudAmount()}
          />
          <RowWithPrepaid
            label={"Extra Traffic"}
            prepaid={getPrepaid("overuse,server_traf_max")}
            amount={getAmount("overuse,server_traf_max")}
          />
          <RowWithPrepaid
            label={"Public IPs"}
            prepaid={getPrepaid("overuse,ip_num")}
            amount={getAmount("overuse,ip_num")}
          />
          <RowWithPrepaid
            label={"Anycast IPs"}
            prepaid={getPrepaid("overuse,anycast_ip_num")}
            amount={getAmount("overuse,anycast_ip_num")}
          />
        </Segment>
        <Segment>
          <Header as="h4">Block Storage</Header>
          <RowWithPrepaid
            label={"HDD L2"}
            prepaid={getPrepaid("overuse,block_hdd_l2_du")}
            amount={getAmount("overuse,block_hdd_l2_du")}
          />
          <RowWithPrepaid
            label={"HDD L3"}
            prepaid={getPrepaid("overuse,block_hdd_l3_du")}
            amount={getAmount("overuse,block_hdd_l3_du")}
          />
          <RowWithPrepaid
            label={"SSD L2"}
            prepaid={getPrepaid("overuse,block_ssd_l2_du")}
            amount={getAmount("overuse,block_ssd_l2_du")}
          />
          <RowWithPrepaid
            label={"SSD L3"}
            prepaid={getPrepaid("overuse,block_ssd_l3_du")}
            amount={getAmount("overuse,block_ssd_l3_du")}
          />
        </Segment>
        <Segment>
          <Header as="h4">Object Storage</Header>
          <RowWithPrepaid
            label={"Data"}
            prepaid={getPrepaid("overuse,object_data")}
            amount={getAmount("overuse,object_data")}
          />
          <RowWithPrepaid
            label={"Traffic"}
            prepaid={getPrepaid("overuse,object_traf")}
            amount={getAmount("overuse,object_traf")}
          />
        </Segment>
        <Segment>
          <SimpleRow label={"Private Cloud"} amount={getServersTotal("model")}/>
          <List style={{marginLeft: ".7em", marginTop: ".7em"}}>
            <List.Item>
              <RowWithPrepaid label={"vCPU"} prepaid={privateCloudInfo("cpu")}/>
            </List.Item>
            <List.Item>
              <RowWithPrepaid label={"RAM"} prepaid={privateCloudInfo("ram")}/>
            </List.Item>
            <List.Item>
              <RowWithPrepaid label={"DISK"} prepaid={privateCloudInfo("disk")}/>
            </List.Item>
          </List>
          <RowWithPrepaid
            label={"IP Range"}
            prepaid={getPrepaid("overuse,cluster_ip_num")}
            amount={getAmount("overuse,cluster_ip_num")}
          />
          <RowWithPrepaid
            label={"Traffic"}
            prepaid={getPrepaid("overuse,cluster_traf_max") + getPrepaid("overuse,cluster_traf95_max")}
            amount={getAmount("overuse,cluster_traf_max") + getAmount("overuse,cluster_traf95_max")}
          />
        </Segment>
        <Segment clearing>
          <Dimmer active={!uiState.appIsInSync} inverted>
            <Loader inverted/>
          </Dimmer>
          <Grid columns='equal'>
            <Grid.Column>
              <Header as="h4" floated="left">Total Cost</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Header as="h4">{ah.total ? t("total", {total: ah.total}) : ""}</Header>
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment.Group>
      {/*<Segment.Group>*/}
      {/*  <Segment>*/}
      {/*    <SimpleRow label={"Amazon"} amount={amazon.total}/>*/}
      {/*    <SimpleRow label={"Azure"} amount={azure.total}/>*/}
      {/*    <SimpleRow label={"Google"} amount={google.total}/>*/}
      {/*    <SimpleRow label={"Digital Ocean"} amount={digitalOcean.total}/>*/}
      {/*  </Segment>*/}
      {/*</Segment.Group>*/}
    </>
  );
});

export default Sidebar;
