import {useContext} from "react";
import Dinero from "dinero.js";
import RootStoreContext from "../stores/RootStore";
import {useTranslation} from "react-i18next";
import {toJS} from "mobx";
import {find} from "lodash";

export function asPrice(amount, currency = "USD") {
  return Dinero({
    amount: amount,
    currency: currency,
  }).toFormat("$0,0.00");
}

export function asAttributeLabel(type, unit = "") {
  const {ah} = useContext(RootStoreContext);
  const {t} = useTranslation();
  const prices = toJS(ah.prices);
  const price = find(prices, price => price.type.name === type);
  const translatedUnit = unit === "" ? t(price.prepaid.unit) : t(unit);
  const formattedPrice = asPrice(parseInt(price.price.amount), price.price.currency);

  return `(${formattedPrice}/${translatedUnit})`;
}