import {createContext} from "react";
import {UiState} from "./UiState";
import Calculator from "./Calculator";

class RootStore {
  constructor() {
    this.uiState = new UiState(this);
    this.ah = new Calculator(this);
    // this.amazon = new Calculator("Calculator-Amazon", "reseller");
    // this.azure = new Calculator("Calculator-Azure", "reseller");
    // this.google = new Calculator("Calculator-Google", "reseller");
    // this.digitalOcean = new Calculator("Calculator-DigitalOcean", "reseller");
  }

  initCalculators(apiRoot, tariffName, seller) {
    this.ah.setApiRoot(apiRoot).setSeller(seller).setTariffName(tariffName).fillWithApiData().initReactions();
    // todo: setup other calculators ...

    return this;
  }
}

const RootStoreContext = createContext(new RootStore());

export default RootStoreContext;