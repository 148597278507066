import React from "react";

const Row = ({children}) => (
  <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
    {children}
  </div>
);

const SimpleRow = ({label, amount}) => {
  return (
    <Row>
      <b>{label}</b>
      <span>{amount}</span>
    </Row>
  );
};

const RowWithPrepaid = ({label = "", prepaid = "", amount = ""}) => {
  if (!amount && !prepaid) {
    return "";
  }

  return (
    <Row>
      <span><b>{label}:</b> {prepaid}</span>
      <span>{amount}</span>
    </Row>
  );
};

export {Row, RowWithPrepaid, SimpleRow};