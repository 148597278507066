import {action, makeAutoObservable, when, toJS, runInAction, reaction} from "mobx";
import {filter, find, findIndex, forEach, split, startsWith, update} from "lodash";
import request from "../utils/request";
import {asPrice} from "../utils/formatter";
import {useTranslation} from "react-i18next";

const calculateTotal = charges => {
  let total = 0;
  forEach(charges, charge => {
    total += parseInt(charge.sum.amount);
  });

  return asPrice(total);
};

class Calculator {
  apiRoot;
  tariffName;
  seller;
  rootStore;

  tariff = null;
  charges = [];
  prices = [];
  models = [];
  configs = [];
  currency = "USD";

  // Public Cloud
  publicCloud = [];
  public_cloud_id;

  server_traf_max = 0;
  ip_num = 0;
  anycast_ip_num = 0;

  // Storage
  block_hdd_l2_du = 0;
  block_hdd_l3_du = 0;
  block_ssd_l2_du = 0;
  block_ssd_l3_du = 0;

  object_data = 0;
  object_traf = 0;

  // Private Cloud
  privateCloud = [];
  cpu;
  ram;
  disk;

  disk_type = "nvme";
  traf_type = "tb";

  cluster_ip_num = 0;
  cluster_traf95_max = 0;
  cluster_traf_max = 0;

  constructor(rootStore) {
    makeAutoObservable(
      this, {
        rootStore: false,
      },
    );
    this.rootStore = rootStore;
  }

  setApiRoot(apiRoot) {
    this.apiRoot = apiRoot;

    return this;
  }

  setTariffName(tariffName) {
    this.tariffName = tariffName;

    return this;
  }

  setSeller(seller) {
    this.seller = seller;

    return this;
  }

  get canMakeRequest() {
    return this.apiRoot && this.tariffName;
  }

  fillWithApiData() {
    when(
      () => !this.tariff && this.canMakeRequest,
      () => this.fetchTariff(),
      {
        name: "fetching plan",
      },
    );
    when(
      () => this.tariff && this.canMakeRequest && !this.models.length,
      () => this.fetchModels(),
      {
        name: "fetching models",
      },
    );
    when(
      () => this.tariff && this.canMakeRequest && !this.configs.length,
      () => this.fetchConfigs(),
      {
        name: "fetching configs",
      },
    );

    return this;
  }

  initReactions(delay = 500) {
    reaction(() => this.prepareQueryParams(this.plan), () => {
      if (this.tariff && this.canMakeRequest) {
        this.calculate(this.prepareQueryParams(this.plan));
      }
    }, {
      name: "start calculation",
      delay: delay,
    });

    return this;
  }

  addPublicCloud(item) {
    if (!this.public_cloud_id) {
      return;
    }
    const idx = findIndex(this.publicCloud, entry => parseInt(entry.id) === parseInt(item.id));
    if (idx !== -1) {
      update(this.publicCloud, [idx, "prepaid", "quantity"], quantity => {
        return (parseInt(quantity) || 0) + 1;
      });
    } else {
      item.prepaid.quantity = parseInt(item.prepaid.quantity) + 1;
      this.publicCloud.push(item);
    }
  }

  addPrivateCloud() {
    if (!this.cpu || !this.ram || !this.disk) {
      return;
    }
    const id = [this.cpu, this.ram, this.disk].join("");
    const idx = findIndex(this.privateCloud, entry => entry.id === id);
    if (idx === -1) {
      const cpu = find(this.prices, price => parseInt(this.cpu) === parseInt(price.target.id));
      const ram = find(this.prices, price => parseInt(this.ram) === parseInt(price.target.id));
      const disk = find(this.prices, price => parseInt(this.disk) === parseInt(price.target.id));
      this.privateCloud.push({
        id: id,
        cpu: cpu,
        ram: ram,
        disk: disk,
        qty: 1,
      });
    } else {
      update(this.privateCloud, [idx, "qty"], qty => {
        return (parseInt(qty) || 0) + 1;
      });
    }
  }

  calculatePrivateCloud(id) {
    const pc = find(this.privateCloud, entry => entry.id === id);
    if (parseInt(pc.qty)) {
      return asPrice(
        parseInt(pc.cpu.price.amount) + parseInt(pc.ram.price.amount) + parseInt(pc.disk.price.amount),
      );
    }

    return null;
  }

  updatePrivateCloud(id, qty) {
    const idx = findIndex(this.privateCloud, entry => entry.id === id);
    update(this.privateCloud, [idx, "qty"], () => {
      return qty;
    });
  }

  updatePublicCloud(item, quantity) {
    const idx = findIndex(this.publicCloud, entry => parseInt(entry.id) === parseInt(item.id));
    update(this.publicCloud, [idx, "prepaid", "quantity"], () => {
      return quantity;
    });
  }

  get publicCloudOptions() {
    const options = [];
    const prices = filter(toJS(this.prices), price => price.type.name === "monthly,hardware" && price.target.type === "config");
    forEach(prices, price => {
      let text = split(price.target.name, "@", 1)[0] + " - " + price.target.label + " - " + asPrice(parseInt(price.price.amount), price.price.currency);
      options.push({
        key: price.target.id,
        text: text,
        value: price.id,
      });
    });

    return options;
  }

  get clusterTrafficOptions() {
    const {t} = useTranslation();
    const options = [];
    const type = {
      mbps: "overuse,cluster_traf95_max",
      tb: "overuse,cluster_traf_max",
    };
    const price = find(toJS(this.prices), price => price.type.name === type[this.traf_type]);
    if (this.traf_type === "mbps") {
      forEach([250, 500, 1000, 3000, 5000, 10000], qty => {
        options.push({
          key: qty,
          text: t(`${qty} mbps`) + " - " + asPrice(parseInt(qty * price.price.amount), price.price.currency),
          value: qty,
        });
      });
    }
    if (this.traf_type === "tb") {
      forEach([50, 100, 200, 300, 400, 500, 1000], qty => {
        options.push({
          key: qty,
          text: `${qty} ${t(price.prepaid.unit)} - ${asPrice(parseInt(qty * price.price.amount), price.price.currency)}`,
          value: qty,
        });
      });
    }

    return options;
  }

  get dedicatedIpRangeOptions() {
    const {t} = useTranslation();
    const options = [];
    const price = find(toJS(this.prices), price => price.type.name === "overuse,cluster_ip_num");
    forEach([16, 32, 64, 128, 256, 512, 1024, 2048], qty => {
      let formattedPrice = asPrice(parseInt(qty) * parseInt(price.price.amount), price.price.currency);
      options.push({
        key: qty,
        text: t(qty, {qty}) + ` - ${formattedPrice}`,
        value: qty,
      });
    });

    return options;
  }

  get cpuOptions() {
    const options = [];
    const models = filter(toJS(this.models), model => model.type === "chassis");
    forEach(models, model => {
      const price = find(this.prices, price => parseInt(price.target.id) === parseInt(model.id));
      options.push({
        key: model.id,
        text: price.target.label,
        value: model.id,
      });
    });

    return options;
  }

  get ramOptions() {
    const options = [];
    const models = filter(toJS(this.models), model => model.type === "ram");
    forEach(models, model => {
      const price = find(this.prices, price => parseInt(price.target.id) === parseInt(model.id));
      options.push({
        key: model.id,
        text: price.target.label,
        value: model.id,
      });
    });

    return options;
  }

  get diskOptions() {
    const options = [];
    const models = filter(toJS(this.models), model => model.type === "ssd" && model.partno.toLowerCase().includes(this.disk_type));
    forEach(models, model => {
      const price = find(this.prices, price => parseInt(price.target.id) === parseInt(model.id));
      options.push({
        key: model.id,
        text: price.target.label,
        value: model.id,
      });
    });

    return options;
  }

  prepareCloudServers(plan) {
    const result = [];
    forEach(toJS(this.publicCloud), item => {
      result.push(Object.assign({}, plan, {
        target_fullname: item.target.type + ":" + item.target.name,
        type_name: item.type.name,
        amount: item.prepaid.quantity,
      }));
    });

    return result;
  }

  preparePrivateServers(plan) {
    const result = [];
    forEach(toJS(this.privateCloud), item => {
      forEach(["cpu", "ram", "disk"], part => {
        result.push(Object.assign({}, plan, {
          target_fullname: item[part].target.type + ":" + item[part].target.name,
          type_name: item[part].type.name,
          amount: item.qty,
        }));
      });
    });

    return result;
  }

  get total() {
    if (this.charges.length) {
      return calculateTotal(toJS(this.charges));
    }

    return "";
  }

  get plan() {
    return {
      plan_fullname: this.tariffName + "@" + this.seller,
    };

  }

  updateValue(name, value) {
    this[name] = value;
  }

  prepare(type, amount, plan) {
    const price = find(toJS(this.prices), price => price.type.name === type);
    if (price) {
      return Object.assign({}, plan, {
        type_name: type,
        unit: price.prepaid.unit,
        amount: amount,
      });
    }
  }

  fetchTariff() {
    this.rootStore.uiState.startRequest();
    request({
      apiRoot: this.apiRoot,
      path: "PlansSearch",
      params: {
        where: {
          name: this.tariffName,
          available_for_seller: this.seller,
        },
        with: ["prices"],
      },
    }).then(action("fetchTariff", tariffs => {
      this.rootStore.uiState.finishRequest();
      if (tariffs.length) {
        this.tariff = tariffs[0];
        this.prices = tariffs[0].prices;
        this.currency = "USD";
      }
    }));
  }

  fetchModels() {
    const ids = [];
    forEach(toJS(this.prices), price => {
      if (price.target.type === "model") {
        ids.push(price.target.id);
      }
    });
    this.rootStore.uiState.startRequest();
    request({
      apiRoot: this.apiRoot,
      path: "modelsSearch",
      params: {
        id_in: ids,
      },
    }).then(action("fetchModels", models => {
      this.rootStore.uiState.finishRequest();
      this.models = models;
    }));
  }

  fetchConfigs() {
    const ids = [];
    forEach(toJS(this.prices), price => {
      if (price.target.type === "config") {
        ids.push(price.target.id);
      }
    });
    this.rootStore.uiState.startRequest();
    request({
      apiRoot: this.apiRoot,
      path: "configsSearch",
      params: {
        id_in: ids,
        client: this.seller,
        with_props: 1,
      },
    }).then(action("fetchConfigs", configs => {
      this.rootStore.uiState.finishRequest();
      this.configs = configs;
    }));
  }

  prepareQueryParams(plan) {
    return [
      ...this.prepareCloudServers(plan),
      ...this.preparePrivateServers(plan),
      this.prepare("overuse,server_traf_max", this.server_traf_max, plan),
      this.prepare("overuse,ip_num", this.ip_num, plan),
      this.prepare("overuse,anycast_ip_num", this.anycast_ip_num, plan),
      this.prepare("overuse,block_hdd_l2_du", this.block_hdd_l2_du, plan),
      this.prepare("overuse,block_hdd_l3_du", this.block_hdd_l3_du, plan),
      this.prepare("overuse,block_ssd_l2_du", this.block_ssd_l2_du, plan),
      this.prepare("overuse,block_ssd_l3_du", this.block_ssd_l3_du, plan),
      this.prepare("overuse,object_data", this.object_data, plan),
      this.prepare("overuse,object_traf", this.object_traf, plan),
      this.prepare("overuse,cluster_ip_num", this.cluster_ip_num, plan),
      this.prepare("overuse,cluster_traf_max", this.cluster_traf_max, plan),
      this.prepare("overuse,cluster_traf95_max", this.cluster_traf95_max, plan),
    ];
  }

  calculate(queryParams) {
    queryParams = filter(queryParams, param => param.amount > 0);
    if (queryParams.length > 0) {
      this.rootStore.uiState.startRequest();
      request({
        apiRoot: this.apiRoot,
        path: "ActionsCalculate",
        params: queryParams,
      }).then(
        action(`fetch calculation for ${this.tariffName}`, charges => {
          this.rootStore.uiState.finishRequest();
          this.charges = filter(charges, charge => charge.sum.amount > 0);
        }),
      );
    } else {
      runInAction(() => {
        this.charges = [];
      });
    }
  }
}

export default Calculator;